import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.contract.edit');
import { OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-contract-edit',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminContractEditComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( ChangeDetectorRef)  private cdr: ChangeDetectorRef) { }

    @Input() id: string;
    public items: any = [];
    public loaded: boolean = false;
    public status: any = false;
    public search: any = { page: 1 };
    public data: any = {
        name: '',
        cost: '',
        entity: '',
        method: '',
        status: '',
        type: '',
        pm: '',
        pm_name: '',
        start: '',
        end: '',
        created: '',
        updated: '',
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public users: any = [];
    public pmResults: any = [];
    public showPmModal: boolean = false;
    public selectedPm: any = null;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(["admin", "manager"], "/");
        if (this.id !== "new") await this.load();
        await this.service.render();
    }

    public async load() {
        try {
            this.id = parseInt(WizRoute.segment.id, 10);
            const { code, data } = await wiz.call("load", { id: this.id });
            if (code === 200) {
                this.data = data.data;
            } else {
                await this.alert("데이터를 불러오는 데 실패했습니다.");
            }
        } catch (error) {
            await this.alert("데이터를 불러오는 데 실패했습니다.");
        }
    }

    public async loadAllUsers(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        await this.service.render();

        const { code, data } = await wiz.call('search_users', this.search);
        if (code === 200) {
            let { rows, lastpage, total } = data;

            this.pmResults = rows.filter(user => user.role === 'manager'); // PM 결과 필터링
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;

            this.totalItems = total;  // 전체 게시물 수 저장
            this.pagenation.start = startpage;
            this.pagenation.end = lastpage;

            this.loaded = true;
            this.cdr.detectChanges();
        }

        await this.service.render();
    }

    public openPmModal() {
        this.showPmModal = true;
        this.loadAllUsers(); // Load all users initially
    }

    public closePmModal() {
        this.showPmModal = false;
    }

    public addPm() {
        if (this.selectedPm) {
            this.data.pm = this.selectedPm.id;
            this.data.pm_name = this.selectedPm.name;
        }
        this.closePmModal();
        this.cdr.detectChanges(); // Change detection trigger
    }

    public removePm() {
        this.data.pm = '';
        this.data.pm_name = '';
        this.cdr.detectChanges(); // Change detection trigger
    }


    public async cancel() {
        await this.service.href('/admin/task/contract');
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async submit() {
        // 용역명 확인
        if (!this.data.name || this.data.name.trim() === '') {
            await this.alert('용역명을 작성해야 합니다.', 'error');
            return;
        }

        // 시작일과 종료일 확인
        if (!this.data.start || !this.data.end) {
            await this.alert('점검 기간의 시작일과 종료일을 모두 입력해야 합니다.', 'error');
            return;
        }

        let body = JSON.parse(JSON.stringify(this.data));
        const call = this.id == "new" ? "save" : "update";
        let { code, data } = await wiz.call(call, body);
        if (code !== 200) {
            return await this.alert(data);
        }
        await this.alert((this.id == "new" ? "생성" : "수정") + "이 완료되었습니다!", "success");
        await this.service.href("/admin/task/contract");
    }



    public async init() {
        this.data = {
            name: '',
            cost: '',
            entity: '',
            method: '',
            type: '',
            pm: '',
            pm_name: '',
            start: '',
            end: '',
            created: '',
            updated: '',
        };
        await this.service.render();
    }
}

export default ComponentAdminContractEditComponent;