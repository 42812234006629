import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.alert');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-season-alert',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.season.alert/view.scss */
@charset "UTF-8";
/* 기본 모달 스타일 */
.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* z-index를 높여 모달을 위로 띄움 */
}

.modal {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* 그림자 */
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  animation: modal-fade-in 0.3s ease-in-out;
}

@keyframes modal-fade-in {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* 모달 닫기 버튼 */
.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #666;
  transition: color 0.2s ease;
}

.btn-close:hover {
  color: #000;
}

/* 상태별 배경 색 */
.modal-status {
  height: 5px;
}

.bg-red {
  background-color: #f44336; /* 에러 색상 */
}

.bg-blue {
  background-color: #3E3EF4; /* 성공 색상 */
}

.bg-yellow {
  background-color: #ff9800; /* 경고 색상 */
}

/* 모달 본문 스타일 */
.modal-body {
  padding: 20px;
}

.modal-body h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.modal-body .text-muted {
  color: #666;
}

/* 모달 푸터 버튼 */
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #e5e5e5;
}

.modal-footer a.btn {
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  color: #fff;
  transition: background-color 0.2s ease;
}

.btn-red {
  background-color: #f44336;
}

.btn-blue {
  background-color: #3E3EF4;
}

.btn-yellow {
  background-color: #ff9800;
}

.btn:hover {
  opacity: 0.9;
}

/* 버튼 간격 */
.modal-footer a + a {
  margin-left: 10px;
}

/* 캔슬 버튼 링크 */
.btn-link {
  color: #666;
  background: none;
  padding: 0;
  font-weight: 500;
  cursor: pointer;
}

.btn-link:hover {
  text-decoration: underline;
  color: #333;
}`],
})
export class PortalSeasonAlertComponent implements OnInit {
    @Input() model: any = null;

    constructor(@Inject( Service) public service: Service) {
        if (!this.model) this.model = service.alert;
    }

    public async ngOnInit() {
    }
}

export default PortalSeasonAlertComponent;