import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.main.project');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-main-project',
template: templateSource || '',
    styles: [``],
})
export class ComponentMainProjectComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public totalItems: number = 0;  // 전체 게시물 수

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }


    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        const { code, data, total } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage} = data;
        this.totalItems = total;
        this.items = rows;
        this.pagenation.start = Math.floor((page - 1) / 10) * 10 + 1;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public onEnter(event: KeyboardEvent) {
        event.preventDefault();
        this.load();
    }
}

export default ComponentMainProjectComponent;