import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.main.notice');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-main-notice',
template: templateSource || '',
    styles: [``],
})
export class ComponentMainNoticeComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public categoryMap: any = { notice: '공지사항', report: '보고서', article: '보도자료' };
    public category: any = 'notice';
    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async ngOnInit() {
        await this.service.init();
        // await this.service.auth.allow('admin', '/authenticate');
        await this.load();
    }

    public totalItems: number = 0;  // 전체 게시물 수

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        // this.items = [];
        // await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;

        let { rows, lastpage, total } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.totalItems = total;  // 전체 게시물 수 저장
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public getItemNumber(index: number): number {
        // 전체 게시물 수에서 현재 페이지와 인덱스를 고려하여 게시물 번호 계산
        return this.totalItems - ((this.search.page - 1) * 5 + index);
    }

}

export default ComponentMainNoticeComponent;