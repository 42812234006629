import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.template.edit');
import { OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-template-edit',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.admin.template.edit/view.scss */
.bg-admin-project {
  padding: 3rem;
}`],
})
export class ComponentAdminTemplateEditComponent implements OnInit {
    public id: string = '';
    public title: string = '';
    public view: string = 'template.frame';
    public frame: any = [{ id: "", type: "col", value: "", fix: false, child: [] }];
    public items: any = [[]];
    public conditions: any = [];
    @ViewChild('compFrame') compFrame;
    @ViewChild('compItem') compItem;
    @ViewChild('compForm') compForm;

    // public returnTo: string = "notset";

    constructor(@Inject( Service) public service: Service) { this.id = WizRoute.segment.id; }

    public async ngOnInit() {
        await this.service.init();
        if (this.id !== "new") {
            const { code, data } = await wiz.call("load", { data: JSON.stringify({ id: this.id }) });
            if (code == 200) {
                this.title = data.title;
                this.frame = data.frame;
                this.items = data.item;
                this.conditions = data.conditions || [];
            }
            await this.service.render();
        }
    }

    public async next() {
        if (this.view == "template.frame") {
            this.view = "template.item";
            this.frame = this.compFrame.frame;
        }
        else if (this.view == "template.item") {
            this.view = "template.form";
            this.items = this.compItem.items;
        }
        await this.service.render();
    }

    public async previous() {
        if (this.view == "template.form") {
            this.view = "template.item";
            this.conditions = this.compForm.conditions;
        }
        else if (this.view == "template.item") {
            this.view = "template.frame";
            this.items = this.compItem.items;
        }
        await this.service.render();
    }

    public async save() {
        let api = "save"
        if (this.conditions.length) this.conditions = this.compForm.conditions;
        let body = { title: this.title, frame: this.frame, item: this.items, conditions: this.conditions };
        if (this.id !== "new") {
            body.id = this.id;
            api = "modify"
        }
        const { code } = await wiz.call(api, { data: JSON.stringify(body) });
        if (code !== 200) {
            alert("error 발생!")
        } else alert("저장되었습니다!")
    }

    public async extract() {
        let body = { frame: this.frame, items: this.items };
        const { code, data } = await wiz.call("extract", { data: JSON.stringify(body) });
    }
}

export default ComponentAdminTemplateEditComponent;