import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.auth.forgotemail');
import { OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'wiz-page-auth-forgotemail',
template: templateSource || '',
    styles: [``],
})
export class PageAuthForgotemailComponent implements OnInit {
    @Input() title: any;
    step = 1;
    data = {
        name: '',
        phone: '',
    };
    foundEmail = '';
    errorMessage = '';

    constructor(@Inject( Router)         private router: Router,@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         private cdr: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/main");
    }

    public formatPhone() {
        const trimmed = this.data.phone.replace(/\D+/g, '');

        if (trimmed.length === 11) {
            this.data.phone = trimmed.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else if (trimmed.length === 10) {
            this.data.phone = trimmed.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
        this.cdr.detectChanges();
    }

    public async findEmail() {
        this.errorMessage = '';  // Reset error message
        if (!this.data.name || !this.data.phone) {
            this.errorMessage = '이름과 전화번호를 입력해 주세요.';
            this.cdr.detectChanges();
            return;
        }

        const { code, data } = await wiz.call('find_email', {
            name: this.data.name,
            phone: this.data.phone
        });

        if (code === 200) {
            this.foundEmail = data.email;
            this.errorMessage = '';
            this.step = 2;
            this.cdr.detectChanges();
        } else {
            this.errorMessage = '입력하신 정보와 일치하는 이메일이 없습니다.';
            console.error('Error finding email:', data);
            this.cdr.detectChanges();
        }
    }

    public navigateToLogin() {
        this.router.navigate(['/login']);
    }
}

export default PageAuthForgotemailComponent;