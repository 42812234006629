import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.inspection.check');
import { OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';

@Component({
    selector: 'wiz-component-inspection-check',
template: templateSource || '',
    styles: [``],
})
export class ComponentInspectionCheckComponent implements OnInit {
    @Input() id: string;
    @Input() title: any;
    @Input() frame: any;
    @Input() items: any;
    @Input() conditions: any;

    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    public config: any = {
        col: {
            animation: 0
        },
        child: {
            animation: 0,
            group: 'list'
        }
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');

        try {
            // Load project details
            const { code, data } = await wiz.call("load", { data: JSON.stringify({ id: this.id }) });

            if (code === 200 && data) {
                const inspectorIds = data.inspector ? data.inspector.split(',') : [];
                const userId = this.service.auth.session.id;

                // Log inspectorIds and userId for debugging

                // Check if the current user is an inspector
                if (!inspectorIds.includes(userId)) {
                    await this.alert("체크리스트 작성 권한이 없습니다.", "권한 없음");
                    this.service.href('/'); // 권한이 없을 경우 다른 페이지로 리디렉션
                    return;
                }


                // Load the checklist details if user is authorized
                this.title = data.name;
                this.frame = data.template;
                this.items = data.response;
                this.conditions = data.conditions || [];
                await this.service.render();
            } else {
                await this.alert("프로젝트 정보를 불러오는 데 실패했습니다.", "오류");
                this.service.href('/'); // 프로젝트 정보를 불러오지 못한 경우 다른 페이지로 리디렉션
            }
        } catch (error) {
            await this.alert("프로젝트 정보를 불러오는 데 실패했습니다.", "오류");
            this.service.href('/'); // 프로젝트 정보를 불러오지 못한 경우 다른 페이지로 리디렉션
        }
    }

    public hasRow() {
        return this.frame.filter(item => item.child.length > 0);
    }

    public row() {
        let answer = [...this.frame];
        this.frame.forEach((item, i) => {
            if (item.type == "col") {
                answer.splice(i, 1, ...item.child);
            }
        });
        return answer;
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async extract() {
        let body = { title: this.title, frame: this.frame, items: this.items, conditions: this.conditions };
        let download = wiz.url("extract?data=" + JSON.stringify({ id: this.id }));
        window.open(download, '_blank');
    }

    public async save() {
        let body = { id: this.id, response: this.items };
        const { code, data } = await wiz.call("save", { data: JSON.stringify(body) });
        if (code === 200) alert("저장완료!");
    }

    public goBack() {
        this.location.back();
    }
}

export default ComponentInspectionCheckComponent;