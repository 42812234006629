import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.auth.signup');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-auth-signup',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.auth.signup/view.scss */
/* src/app/component.auth.signup/view.scss: no such file or directory */`],
})
export class ComponentAuthSignupComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        // await this.service.auth.allow(false, '/main');
    }

    public status: any = false;
    public data: any = {
        email: '',
        password: '',
        name: ''
    };

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async submit() {
        let user = JSON.parse(JSON.stringify(this.data));
        console.log(user)
        user.password = this.service.auth.hash(user.password);
        let { code, data } = await wiz.call("submit", user);
        console.log(user)
        if (code == 200) {
            await this.service.render();
            location.href = "/auth/login"
            return;
        }
        await this.alert(data);
    }

}

export default ComponentAuthSignupComponent;