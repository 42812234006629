import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Component } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-component-nav-user',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav.user/view.scss */
body {
  width: 100%;
}

.block-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}`],
})
export class ComponentNavUserComponent implements OnInit {
    public isUserMenuOpen: boolean = false;
    public currentUrl: string = '';

    constructor(@Inject( Service) public service: Service,@Inject( Router)  private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.urlAfterRedirects;
                this.isUserMenuOpen = false;
            }
        });
    }

    public menuStyle: any = {
        'top': '80px',
        'width': '140px',
        'min-width': '0',
        'background-color': 'white',
        'color': '#fff'
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.service.render();
    }

    public toggleUserMenu() {
        this.isUserMenuOpen = !this.isUserMenuOpen;
    }

    public hasAuth(roles: string) {
        roles = roles.split(",");
        return roles.includes(this.service.auth.session.role);
    }

    // 메뉴 외부 클릭 시 드롭다운 닫기
    @HostListener('document:click', ['$event.target'])
    public clickout(targetElement: HTMLElement) {
        if (!this.isUserMenuOpen) {
            return;
        }
        const clickedInside = targetElement.closest('.relative.ml-2'); // 드롭다운 외부 클릭 확인
        if (!clickedInside) {
            this.isUserMenuOpen = false;
        }
    }

    public isActive(link: string) {
        return this.currentUrl.startsWith(link) || (link === '/contract' && this.currentUrl.startsWith('/project'));
    }


    public async logout() {
        await wiz.call("logout");
        location.href = "/login";
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "inline-flex items-center border-b-2 border-stone-500 px-1 pt-1 text-sm font-medium text-gray-900";
        }
        return "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
    }
}

export default ComponentNavUserComponent;