import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";
import { validation } from "src/libs/validation";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [``],
})
export class PageMypageComponent implements OnInit {
    public data: any = {};
    public passwd: any = {};

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();  // 데이터 로드
        await this.service.render();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code === 200) {
            this.data = data;
        } else {
            await this.service.alert.show({ title: "오류", message: "데이터 로드에 실패했습니다.", action: "확인", actionBtn: "error", cancel: false, status: "error" });
        }
        await this.service.render();
    }

    public async update() {
        await this.service.loading.show();
        const { code, data } = await wiz.call("update", { data: JSON.stringify(this.data) });
        this.passwd = {};

        await this.service.loading.hide();
        if (code === 200) {
            await this.service.alert.show({ title: "마이페이지", message: "회원 정보가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
        } else {
            await this.service.alert.show({ title: "오류", message: "회원 정보 변경에 실패했습니다.", action: "확인", actionBtn: "error", cancel: false, status: "error" });
        }
        await this.load(); // 변경 후 데이터 다시 로드
    }

    public async updatePassword() {
        if (this.passwd.change !== this.passwd.repeat) {
            await this.service.alert.show({ title: "오류", message: "비밀번호가 일치하지 않습니다.", action: "확인", actionBtn: "error", cancel: false, status: "error" });
            return;
        }

        if (this.passwd.change.length < 8) return await this.service.alert.show({ title: "오류", message: "8글자 이상의 비밀번호를 설정해주세요.", action: "확인", actionBtn: "error", cancel: false, status: "error" });
        if (this.passwd.change.search(/[a-z]/i) < 0) return await this.service.alert.show({ title: "오류", message: "비밀번호에 알파벳을 포함해주세요.", action: "확인", actionBtn: "error", cancel: false, status: "error" });
        if (this.passwd.change.search(/[0-9]/) < 0) return await this.service.alert.show({ title: "오류", message: "비밀번호에 숫자를 포함해주세요.", action: "확인", actionBtn: "error", cancel: false, status: "error" });

        await this.service.loading.show();
        let passwd: any = {
            current: this.service.auth.hash(this.passwd.current),
            change: this.service.auth.hash(this.passwd.change)
        };
        const { code } = await wiz.call("update_password", passwd);
        await this.service.loading.hide();
        if (code === 200) {
            await this.service.alert.show({ title: "마이페이지", message: "비밀번호가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
            this.passwd = {};
        } else {
            await this.service.alert.show({ title: "오류", message: "비밀번호를 확인해주세요.", action: "확인", actionBtn: "error", cancel: false, status: "error" });
        }
        await this.service.render();
    }
}

export default PageMypageComponent;