import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.auth.forgotpw');
import { OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
    selector: 'wiz-component-auth-forgotpw',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.auth.forgotpw/view.scss */
@charset "UTF-8";
/* 기본 스타일: 웹 (1920x1080 기준) */
.login-brand-image {
  /* Layout Properties */
  top: 294px;
  left: 820px;
  width: 260px;
  height: 65px;
  /* UI Properties */
  opacity: 1;
}

.container-tight {
  top: 171px;
  left: 628px;
  width: 664px;
  height: 716px;
}

.mb-4 {
  margin-top: 8.5rem;
  margin-bottom: 0 !important;
}

.login-text {
  margin-top: 4.5rem;
  font: normal normal bold 30px/38px SUIT;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.login-content {
  margin-top: 1.5rem;
  font: normal normal normal 16px/20px SUIT;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
}

.card-login-field {
  width: 320px;
  height: 55px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DCDCDC;
  border-radius: 12px;
  opacity: 1;
  margin-left: 7rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

input::placeholder {
  font: normal normal normal 18px/23px SUIT;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
}

.login-btn {
  width: 320px;
  height: 55px;
  background: #3E3EF4 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  margin-left: 7rem;
  font: normal normal bold 18px/23px SUIT;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.login-plus {
  justify-content: center;
  display: flex;
}

.login-find {
  text-decoration-skip-ink: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 8.3px;
  margin-right: 10px;
  font: normal normal normal 14px/18px SUIT;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
}

/* 모바일 스타일: 모바일 기기에서도 잘 작용되게 */
@media (max-width: 768px) {
  .page-center {
    height: 100% !important;
    padding-bottom: 600px !important;
  }
  .login-brand-image {
    top: 20px;
    left: 50%;
    width: 180px;
    height: 45px;
  }
  .card-md {
    top: 50px;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  .container-tight {
    top: 50px;
    left: 50%;
    width: 90%;
    height: auto;
  }
  .mb-4 {
    margin-top: 2rem;
    margin-bottom: 0 !important;
  }
  .login-text {
    margin-top: 2rem;
    font-size: 24px;
    line-height: 30px;
  }
  .login-content {
    margin-top: 1rem;
    font-size: 14px;
    line-height: 18px;
  }
  .card-login-field {
    width: 80%;
    height: 45px;
    margin-left: 10%;
    margin-bottom: 0.5rem;
  }
  .login-btn {
    width: 80%;
    height: 45px;
    margin-left: 10%;
    font-size: 16px;
    line-height: 20px;
  }
  .login-plus {
    flex-direction: row;
    align-items: center;
  }
  .login-find {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 3px;
    font-size: 12px;
    line-height: 16px;
  }
}`],
})
export class ComponentAuthForgotpwComponent implements OnInit {
    step = 1;
    data = {
        email: '',
        verificationCode: '',
        newPassword: '',
        confirmPassword: ''
    };
    errorMessage = '';

    public async ngOnInit() {


    }
    forgotpw() {
        if (!this.data.email) {
            this.errorMessage = '이메일을 입력해 주세요.';
            return;
        }

        this.authService.sendVerificationCode(this.data.email).subscribe(
            () => {
                this.step = 2;
                this.errorMessage = '';
            },
            (error) => {
                this.errorMessage = '인증번호 전송에 실패했습니다. 다시 시도해 주세요.';
                console.error('인증번호 전송 실패:', error);
            }
        );
    }

    verifyCode() {
        if (!this.data.verificationCode) {
            this.errorMessage = '인증번호를 입력해 주세요.';
            return;
        }

        this.authService.verifyCode(this.data.email, this.data.verificationCode).subscribe(
            () => {
                this.step = 3;
                this.errorMessage = '';
            },
            (error) => {
                this.errorMessage = '인증번호가 일치하지 않습니다. 다시 확인해 주세요.';
                console.error('인증번호 검증 실패:', error);
            }
        );
    }

    resendVerificationCode() {
        this.authService.sendVerificationCode(this.data.email).subscribe(
            () => {
                this.errorMessage = '';
                alert('인증번호가 재전송되었습니다. 이메일을 확인해 주세요.');
            },
            (error) => {
                this.errorMessage = '인증번호 재전송에 실패했습니다. 다시 시도해 주세요.';
                console.error('인증번호 재전송 실패:', error);
            }
        );
    }

    resetPassword() {
        if (!this.data.newPassword || !this.data.confirmPassword) {
            this.errorMessage = '새 비밀번호를 입력해 주세요.';
            return;
        }

        if (this.data.newPassword !== this.data.confirmPassword) {
            this.errorMessage = '새 비밀번호가 일치하지 않습니다. 다시 확인해 주세요.';
            return;
        }

        this.authService.resetPassword(this.data.email, this.data.newPassword).subscribe(
            () => {
                alert('비밀번호가 성공적으로 변경되었습니다. 로그인 페이지로 이동합니다.');
                this.router.navigate(['/login']);
            },
            (error) => {
                this.errorMessage = '비밀번호 변경에 실패했습니다. 다시 시도해 주세요.';
            }
        )
    }
}

export default ComponentAuthForgotpwComponent;