import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.project.inspection');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';
import moment from 'moment';

@Component({
    selector: 'wiz-component-project-inspection',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.project.inspection/view.scss */
@charset "UTF-8";
#no-zoom-page {
  touch-action: manipulation; /* 확대를 방지하는 CSS */
}`],
})
export class ComponentProjectInspectionComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    @Input() id: any;

    public activeTab: string = 'drawing';
    public fd = new FormData();
    public data: any = [];
    public interval_id: any;
    public inspection: any = { position: "", component: "", content: "", scaleW: "", scaleL: "", count: "", amount: "", damage: "", message: "", attachment: [] };
    public modal = {
        id: this.id,
        show: false,
        edit: false,
    }
    public async handleModal(show: boolean = false, edit: boolean = false) {
        this.modal.id = this.id;
        this.modal.show = show;
        this.modal.edit = edit;
        if (show) clearInterval(this.interval_id);
        else {
            this.interval_id = setInterval(async () => {
                if (this.id) await this.load();
            }, 1000);
        }
        await this.service.render();
    }

    public async ngOnInit() {
        await this.service.init();
        if (this.id) await this.load();

        // this.interval_id = setInterval(async () => {
        //     if (this.id) await this.load();
        // }, 1000);
    }

    public async ngOnDestroy() {
        clearInterval(this.interval_id);
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.id });
        this.data = data;
        await this.service.render();
    }

    public async send() {
        await this.service.loading.show();
        let body = JSON.parse(JSON.stringify(this.inspection))
        this.fd.append("body", JSON.stringify(body));
        this.fd.append("id", body.id || this.id);
        const call = body.id ? "update" : "send";
        let url = wiz.url(call);
        let { code, data } = await this.service.file.upload(url, this.fd);
        // await wiz.call("send", { id: this.id, data: JSON.stringify(this.inspection) });
        await this.service.loading.hide();
        if (code !== 200) {
            return await this.alert(data);
        }
        this.inspection = { position: "", component: "", content: "", scaleW: "", scaleL: "", count: "", amount: "", damage: "", message: "", attachment: [] };
        this.fd = new FormData();
        await this.service.render();
        await this.load();
    }

    public async extract() {
        let download = wiz.url("extract?id=" + this.id);
        window.open(download, '_blank');
    }

    public displayDate(date: any) {
        let targetdate = moment(date);
        let diff = new Date().getTime() - new Date(targetdate).getTime();
        diff = diff / 1000 / 60 / 60;
        if (diff > 24) return targetdate.format("M월 D일");
        if (diff > 1) return Math.floor(diff) + "시간전"
        diff = diff * 60;
        if (diff < 2) return "방금전";
        return Math.floor(diff) + "분전";
    }

    public async upload() {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        if (!this.inspection.attachment) this.inspection.attachment = [];
        for (let i = 0; i < files.length; i++) {
            console.log(files[i])
            this.inspection.attachment[i] = files[i].name;
            this.fd.append('file[]', files[i]);
        }
        await this.service.render();
    }

    public async search() {
        this.service.render();
    }

    public imageUrl(image: any) {
        return `url('/file/download/${this.id}/${image}')`;
    }

    public async removeFile(file) {
        let res: any = await this.alert("파일을 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        this.inspection.attachment.remove(file);
        this.service.render();
    }

    public async remove(msg_id) {
        let res: any = await this.alert("메시지를 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("remove", { id: msg_id });
        await this.load();
        this.service.render();
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
}

export default ComponentProjectInspectionComponent;