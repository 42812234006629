import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import $ from 'jquery';

@Component({
    selector: 'wiz-layout-user',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/layout.user/view.scss */
.block-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}`],
})
export class LayoutUserComponent implements OnInit {
    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.service.trigger.bind('scroll-top', async () => {
            $('.season-page-noscroll')[0].scroll({ top: 0, left: 0, behavior: 'smooth' });
        });
    }
}

export default LayoutUserComponent;