import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.template.frame');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-template-frame',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminTemplateFrameComponent implements OnInit {
    @Input() id: string;
    @Input() frame: any;
    public config: any = {
        col: {
            animation: 0
        },
        child: {
            animation: 0,
            group: 'list'
        }
    };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
    }

    public async removeItem(parent: any, target: any) {
        parent.child.remove(target);
        await this.service.render();
    }

    public async addCol(target: any = this.frame) {
        target.push({ id: "", type: "col", value: "", fix: false, child: [] });
        await this.service.render();
    }
    public async addItem(target: any = this.frame, type: string = 'col') {
        let data = { id: "", type: "select", value: "", fix: false };
        if (type == 'score') data = { id: "", type: "radio", value: "", fix: false };
        target.push(data);
        await this.service.render();
    }

    public async removeCol(target: any) {
        let parent: any = this.frame;
        parent.remove(target);
        await this.service.render();
    }

    public hasRow() {
        return this.frame.filter(item => item.child.length > 0);
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

}

export default ComponentAdminTemplateFrameComponent;