import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.auth.forgotpw');
import { OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/portal/season/service";
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'wiz-page-auth-forgotpw',
template: templateSource || '',
    styles: [``],
})
export class PageAuthForgotpwComponent implements OnInit {
    @Input() title: any;
    step = 1;
    data = {
        email: '',
        phone: '',
        verificationCode: '',
        newPassword: '',
        confirmPassword: ''
    };
    errorMessage = '';

    constructor(@Inject( Router)         private router: Router,@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef    )         private cdr: ChangeDetectorRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/main");
    }

    // 이메일 인증 코드 발송
    public async sendEmailVerification() {
        const { code } = await wiz.call("emailAuthentication", {
            email: this.data.email
        });
        if (code === 200) {
            this.step = 2;  // Step 2로 이동
            this.data.verify = true;
            await this.alert("이메일 인증 코드가 발송되었습니다.", "success");
            this.cdr.detectChanges();  // UI 갱신
        } else {
            await this.alert("이메일 인증 코드 발송에 실패했습니다.", "error");
            this.cdr.detectChanges();  // UI 갱신
        }
    }

    public async verifyCode() {
        this.errorMessage = '';  // Reset error message
        if (!this.data.verificationCode) {
            this.errorMessage = '인증번호를 입력해 주세요.';
            this.cdr.detectChanges();
            return;
        }

        const { code, data } = await wiz.call('verify_code', {
            email: this.data.email,
            onetimepass: this.data.verificationCode  // 서버와의 변수명 통일
        });

        if (code === 200) {
            this.errorMessage = '';
            await this.alert('인증번호가 확인되었습니다. 새 비밀번호를 입력해 주세요.', 'success');
            this.step = 3;  // Step을 3으로 변경하여 비밀번호 재설정 단계로
            this.cdr.detectChanges();
        } else {
            this.errorMessage = '인증번호가 일치하지 않습니다. 다시 확인해 주세요.';
            console.error('Error verifying code:', data);
            this.cdr.detectChanges();
        }
    }


    public async resendVerificationCode() {
        this.errorMessage = '';  // Reset error message
        const { code, data } = await wiz.call('send_verification_code', {
            email: this.data.email,
        });

        if (code === 200) {
            this.errorMessage = '';
            await this.alert('인증번호가 재전송되었습니다. 이메일을 확인해 주세요.');
            console.log("Verification code resent successfully.");
            this.cdr.detectChanges();
        } else {
            this.errorMessage = '인증번호 재전송에 실패했습니다. 다시 시도해 주세요.';
            console.error('Error resending verification code:', data);
            this.cdr.detectChanges();
        }
    }

    public async resetPassword() {
        this.errorMessage = '';  // Reset error message
        if (!this.data.newPassword || !this.data.confirmPassword) {
            this.errorMessage = '새 비밀번호를 입력해 주세요.';
            this.cdr.detectChanges();
            return;
        }

        if (this.data.newPassword !== this.data.confirmPassword) {
            this.errorMessage = '새 비밀번호가 일치하지 않습니다. 다시 확인해 주세요.';
            this.cdr.detectChanges();
            return;
        }

        const hashedPassword = this.service.auth.hash(this.data.newPassword);

        const { code, data } = await wiz.call('reset_password', {
            email: this.data.email,
            newPassword: hashedPassword
        });

        if (code === 200) {
            this.step = 4;  // Change to step 4
            this.cdr.detectChanges();
            console.log("Password reset successfully.");
        } else {
            this.errorMessage = '비밀번호 변경에 실패했습니다. 다시 시도해 주세요.';
            console.error('Error resetting password:', data);
            this.cdr.detectChanges();
        }
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status,
        });
    }

    public navigateToLogin() {
        this.router.navigate(['/login']);
    }
}

export default PageAuthForgotpwComponent;