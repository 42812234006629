import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.template.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-template-item',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminTemplateItemComponent implements OnInit {
    @Input() id: string;
    @Input() frame: any;
    @Input() items: any;

    constructor(@Inject( Service) public service: Service) { this.id = WizRoute.segment.id; }

    public async ngOnInit() {
        await this.service.init();
        if (this.items == []) this.items = this.items.map(_ => new Array(this.row()));
    }

    public hasRow() {
        return this.frame.filter(item => item.child.length > 0);
    }

    public async addItem(target: any) {
        const newItem = new Array(this.row()).fill(null);
        newItem.forEach((_, index) => {
            const col = this.frame[index];
            if (col?.type === 'checkbox') {
                newItem[index] = false;  // 체크박스 타입 항목의 기본 값을 false로 설정
            } else {
                newItem[index] = "";     // 다른 타입은 빈 문자열로 초기화
            }
        });
        this.items.push(newItem);
        await this.service.render();
    }

    public async removeItem(target: any) {
        this.items.remove(target);
        await this.service.render();
    }

    public row() {
        let answer = [...this.frame];
        this.frame.forEach((item, i) => {
            if (item.type == "col") {
                answer.splice(i, 1, ...item.child);
            }
        });
        return answer.length;
    }
}

export default ComponentAdminTemplateItemComponent;