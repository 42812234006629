import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.facility.item');
import { OnInit, Input, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-facility-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.facility.item/view.scss */
/* src/app/page.facility.item/view.scss: no such file or directory */`],
})
export class PageFacilityItemComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }
    public facility: string;
    public loaded: boolean = false;
    public tab: string = '';
    public id: string = '';

    public async ngOnInit() {
        await this.service.init();
        await this.setRouteSegments();
    }

    public async ngDoCheck() {
        await this.setRouteSegments();
    }

    private async setRouteSegments() {
        if (this.tab !== WizRoute.segment.tab) {
            this.tab = WizRoute.segment.tab;
            if (this.tab !== "list") {
                this.id = WizRoute.segment.id;
                await this.load();
            }
            await this.service.render();
        }
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.id });
        if (code == 200) {
            this.facility = data;
        }
        await this.service.render();
        this.loaded = true;
    }
}

export default PageFacilityItemComponent;