import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.main.plus');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-main-plus',
template: templateSource || '',
    styles: [``],
})
export class ComponentMainPlusComponent implements OnInit {
    @Input() title: any;

    public async ngOnInit() {
    }
}

export default ComponentMainPlusComponent;