import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.template.form');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-template-form',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminTemplateFormComponent implements OnInit {
    @Input() id: string;
    @Input() frame: any;
    @Input() items: any;
    @Input() conditions: any;

    public condition1: any = ""; // 초기값 설정
    public list = [];
    public condition2: any = ""; // 초기값 설정
    public condition3: any = ""; // 초기값 설정

    constructor(@Inject( Service) public service: Service) { }

    public config: any = {
        col: {
            animation: 0
        },
        child: {
            animation: 0,
            group: 'list'
        }
    };

    public async up() {
        const list = this.row().find(el => el.title == this.condition1)
        this.list = list ? list.value.split('/') : []; // 리스트 업데이트
        await this.service.render();
    }

    public async addItem() {
        this.conditions.push([this.condition1, this.condition2, this.condition3]);
        await this.service.render();
    }

    public async removeItem(target) {
        this.conditions = this.conditions.filter(item => item !== target);
        await this.service.render();
    }

    public async ngOnInit() {
        this.condition1 = "";
        this.condition2 = "";
        this.condition3 = "";

        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
    }

    public hasRow() {
        return this.frame.filter(item => item.child.length > 0);
    }

    public row() {
        let answer = [...this.frame];
        this.frame.forEach((item, i) => {
            if (item.type == "col") {
                answer.splice(i, 1, ...item.child);
            }
        });
        return answer;
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async extract() {
        let body = { frame: this.frame, items: this.items };
        console.log(this.items)
        const { code, data } = await wiz.call("extract", { data: JSON.stringify(body) });
    }
}

export default ComponentAdminTemplateFormComponent;