import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.project.item');
import { OnInit, Input, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-project-item',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.project.item/view.scss */
/* src/app/page.project.item/view.scss: no such file or directory */`],
})
export class PageProjectItemComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }
    public project: any;
    public loaded: boolean = false;
    public tab: string = '';
    public id: string = '';
    public user_id: string = ''; // 로그인한 사용자의 ID

    public async ngOnInit() {
        await this.service.init();
        this.user_id = this.service.auth.session.id;
        await this.setRouteSegments();
    }

    public async ngDoCheck() {
        await this.setRouteSegments();
    }

    private async setRouteSegments() {
        if (this.tab !== WizRoute.segment.tab) {
            this.tab = (WizRoute.segment.tab);
            if (this.tab !== "list") {
                this.id = decodeURI(WizRoute.segment.id);
                if (this.tab !== "inspection") await this.load();
            }
            await this.service.render();
        }
    }

    public async goToInspectionLog() {
        if (!this.isAuthorizedForInspectionLog()) {
            await this.alert("작업 로그 작성 권한이 없습니다.");
            return;
        }
        this.service.href('/project/inspection/' + this.id);
    }

    public async goToChecklist() {
        if (!this.isAuthorizedForChecklist()) {
            await this.alert("체크리스트 작성 권한이 없습니다.");
            return;
        }
        this.service.href('/project/report/' + this.id);
    }

    private isAuthorizedForChecklist(): boolean {
        if (!this.project || !this.project.inspector) {
            return false;
        }

        const isAuthorized = this.project.inspector.some((inspector: any) => inspector.id === this.user_id);

        return isAuthorized;
    }

    private isAuthorizedForInspectionLog(): boolean {
        if (!this.project || !this.project.inspector) {
            return false;
        }

        const isAuthorized = this.project.inspector.some((inspector: any) => inspector.id === this.user_id);

        return isAuthorized;
    }

    public async load() {
        const { code, data } = await wiz.call("loadProject", { id: this.id });
        if (code == 200) {
            this.project = data;
            this.frame = data.template;
            this.items = data.response;
            this.conditions = data.conditions || [];
        } else {
            await this.alert("프로젝트 정보를 불러오는 데 실패했습니다.", "오류");
        }
        await this.service.render();
        this.loaded = true;
    }

    private async alert(message: string) {
        await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: "확인",
            action: "확인",
            status: "error"
        });
    }
}

export default PageProjectItemComponent;