import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.facility.edit');
import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-modal-facility-edit',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.modal.facility.edit/view.scss */
@charset "UTF-8";
.wiz-modal {
  align-items: flex-start;
  padding: 400px;
}

.bg-admin-facility {
  padding: 3rem;
  max-width: 50rem;
}

.workspace-list-custom {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1019607843);
  border-radius: 12px;
  padding: 20px;
}

.page-title-custom {
  font-size: 24px;
  margin-bottom: 20px;
}

.facility-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input.form-control,
.form-group select.form-control,
.form-group textarea.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

/* Select Wrapper */
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-wrapper::after {
  content: "▼";
  font-size: 12px;
  color: #555;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 1;
}

.select-wrapper select.form-control {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  padding-right: 30px !important;
  background-image: none !important;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

button.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button.btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}`],
})
export class ComponentModalFacilityEditComponent implements OnInit {
    public data: any = {};
    public drawing = [{ name: "", value: "" }];
    public types: any[] = [];
    public categories: string[] = [];
    public fd = new FormData();

    @Input() id: string;
    @Input() event: any;

    constructor(@Inject( Location) private location: Location,@Inject( Service)  public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.init();
        if (this.id !== "new") await this.load();
        await this.service.render();
    }

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async init() {
        const { code, data } = await wiz.call("init");
        this.types = data;
    }

    public async load() {
        try {
            if (!this.id) this.id = WizRoute.segment.id;
            const { code, data } = await wiz.call("load", { id: this.id });
            if (code === 200) {
                this.data = data;
                this.drawing = data.drawing ?
                    data.drawing.split(",").map(el => ({ value: "", name: el })) :
                    [{ name: "", value: "" }];
                this.updateCategories();
            } else {
                await this.alert("데이터를 불러오는 데 실패했습니다.");
            }
        } catch (error) {
            await this.alert("데이터를 불러오는 데 실패했습니다.");
        }
        await this.service.render();
    }

    public async submit() {
        this.data.drawing = this.drawing.map(el => el.name).join(",");
        let body = JSON.parse(JSON.stringify(this.data));
        this.fd.append("body", JSON.stringify(body));

        const call = this.id == "new" ? "save" : "update";
        let url = wiz.url(call);
        let { code, data } = await this.service.file.upload(url, this.fd);
        if (code !== 200) {
            return await this.alert(data);
        }
        await this.alert((this.id == "new" ? "생성" : "수정") + "이 완료되었습니다!", "success");
        await this.event.close();
        await this.service.href("/admin/task/facility");
    }

    public updateCategories() {
        const selectedType = this.types.find(el => el.id === this.data.type);
        if (selectedType) {
            this.categories = selectedType.contents.split(", ");
        } else {
            this.categories = [];
        }
    }

    public itemNameChanged(i: number) {
        this.drawing[i].name = this.drawing[i].name.trim();
        this.service.render();
    }


    public async upload(i: number) {
        if (!this.drawing[i].name) return;  // Ensure upload is enabled only if name is present
        let file = await this.service.file.select({ multiple: false });
        if (file.length === 0) return;
        this.drawing[i].value = file[0].name;
        this.fd.append('file[]', file[0], this.drawing[i].name);
        await this.service.render();
    }

    public async remove(i: number) {
        this.drawing.splice(i, 1);
        await this.service.render();
    }
}

export default ComponentModalFacilityEditComponent;