import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.facility');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-modal-facility',
template: templateSource || '',
    styles: [``],
})
export class ComponentModalFacilityComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() event: any;
    @Input() search: any = { text: "" };

    public loading: boolean = false;
    public list: any = [];
    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async ngOnInit() {
        await this.service.init();

        if (!this.search) this.search = {};
        if (!this.search.text) this.search.text = null;
        if (!this.search.page) this.search.page = 1;
        if (!this.search.dump) this.search.dump = 10;

        await this.load();
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", { text: this.search.text });

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }

}

export default ComponentModalFacilityComponent;