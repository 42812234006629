import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.drawing');
import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-modal-drawing',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.modal.drawing/view.scss */
/* src/app/component.modal.drawing/view.scss: no such file or directory */`],
})
export class ComponentModalDrawingComponent implements OnInit {
    @Input() id: any;
    @Input() event: any;
    @ViewChild('blueprintCanvas', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;
    public iconSize: number = 20; // 초기 아이콘 크기
    private context!: CanvasRenderingContext2D;
    private blueprintImg: HTMLImageElement = new Image();

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        this.context = this.canvasRef.nativeElement.getContext('2d')!;
        this.loadBlueprint();
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.id });
        this.blueprintImg.src = data; // 도면 이미지 경로

        this.blueprintImg.onload = () => {
            this.redrawCanvas();
        };
    }
    public async redrawCanvas() {
        // canvas 초기화
        this.context.clearRect(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
        // 도면 그리기
        this.context.drawImage(this.blueprintImg, 0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
        // 아이콘 그리기
        this.drawIcon(50, 50, 'assets/icon.png');
        this.drawIcon(100, 150, 'assets/icon2.png');
    }
    public async drawIcon(x: number, y: number, iconSrc: string) {
        const iconImg = new Image();
        iconImg.src = iconSrc;
        iconImg.onload = () => {
            this.context.drawImage(iconImg, x, y, this.iconSize, this.iconSize);
        };
    }
    public async ngOnChanges() {
        this.redrawCanvas();
    }
    public async saveImage() {
        const image = this.canvasRef.nativeElement.toDataURL('image/png'); // PNG 형식의 데이터 URL 생성

        // 링크를 생성해 이미지 다운로드
        const link = document.createElement('a');
        link.href = image;
        link.download = 'final-image.png'; // 저장될 이미지 파일명
        link.click();
    }
}

export default ComponentModalDrawingComponent;