import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.auth.login');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-auth-login',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.auth.login/view.scss */
.main-view {
  min-height: 100vh;
}`],
})
export class PageAuthLoginComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) {}

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/main");
    }

    public data: any = {
        email: '',
        password: '',
    };

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status,
        });
    }

    public async login() {
        let user = JSON.parse(JSON.stringify(this.data));
        user.password = this.service.auth.hash(user.password);

        let { code, data } = await wiz.call("login", user);
        if (code == 200) {
            location.href = '/';
            return;
        }
        await this.alert(data);
    }
}

export default PageAuthLoginComponent;