import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.project.log');
import { OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';
import moment from 'moment';

@Component({
    selector: 'wiz-component-project-log',
template: templateSource || '',
    styles: [``],
})
export class ComponentProjectLogComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    @Input() id: any;
    @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

    public fd = new FormData();
    public data: any = [];
    public interval_id: any;
    public inspection: any = { position: "", component: "", content: "", scaleW: "", scaleL: "", count: "", amount: "", damage: "", message: "", attachment: [] };

    public async ngOnInit() {
        await this.service.init();
        if (this.id) await this.load();

        this.interval_id = setInterval(async () => {
            if (this.id) await this.load();
        }, 1000);
        this.scrollToBottom();  // 최초 로드 시 가장 아래로 스크롤
    }

    public async ngOnDestroy() {
        clearInterval(this.interval_id);
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.id });
        this.data = data;
        await this.service.render();
    }
    private scrollToBottom(): void {
        try {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
            this.service.render();
        } catch (err) {
            console.error('Scrolling failed', err);
        }
    }

    public async exact() {
        let download = wiz.url("extract?id=" + this.id);
        window.open(download, '_blank');
    }

    public displayDate(date: any) {
        let targetdate = moment(date);
        let diff = new Date().getTime() - new Date(targetdate).getTime();
        diff = diff / 1000 / 60 / 60;
        if (diff > 24) return targetdate.format("M월 D일");
        if (diff > 1) return Math.floor(diff) + "시간전"
        diff = diff * 60;
        if (diff < 2) return "방금전";
        return Math.floor(diff) + "분전";
    }

    public async upload() {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        if (!this.inspection.attachment) this.inspection.attachment = [];
        for (let i = 0; i < files.length; i++) {
            console.log(files[i])
            this.inspection.attachment[i] = files[i].name;
            this.fd.append('file[]', files[i]);
        }
        await this.service.render();
    }

    public async search() {
        this.service.render();
    }

    public imageUrl(image: any) {
        return `url('/file/download/${this.id}/${image}')`;
    }

    public async removeFile(file) {
        let res: any = await this.alert("파일을 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        this.inspection.attachment.remove(file);
        this.service.render();
    }

    public async remove(msg_id) {
        let res: any = await this.alert("메시지를 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("remove", { id: msg_id });
        await this.load();
        this.service.render();
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
}

export default ComponentProjectLogComponent;