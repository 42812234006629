import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.data.component');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-data-component',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.admin.data.component/view.scss */
.bg-admin-facility {
  padding: 3rem;
}

.workspace-list-item {
  white-space: nowrap;
  font-size: 1.125rem !important;
  font-weight: bold;
}

.pb-4 {
  max-width: none;
}

.page-title {
  font-size: 24px;
}

.mt-4-custom {
  margin-top: 1.5rem;
}

.mb-4-custom {
  margin-bottom: 1.5rem;
}

.p-6-custom {
  padding: 1.5rem;
}

.text-center-custom {
  text-align: center;
}

.btn-season-custom {
  background-color: #3E3EF4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-season-custom i {
  margin-right: 5px;
}

.pagination-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-prev-custom,
.pagination-next-custom {
  background: none;
  border: none;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
}

.pagination-list-custom {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination-item-custom {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination-item-custom.active {
  font-weight: bold;
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.ltable-cu {
  background: #F9F9F9 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
  text-align: justify;
}
.ltable-cu th {
  font-size: 1rem;
  background-color: var(--tblr-table-bg);
}

.cursor-pointer td {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1.1rem;
  font-weight: bold;
}

.tnum {
  text-align: center !important;
  width: 10rem;
}

.tday {
  width: 16rem;
}

table tbody {
  font-size: 1.1rem;
  font-weight: bold;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 14px;
  height: 44px;
  padding: 5px 15px;
  width: 100%;
  max-width: 254px;
}

.search-icon {
  margin-right: 10px;
  color: #6c757d;
}

.search-input {
  border: none;
  background-color: transparent;
  outline: none;
  flex: 1;
  font-size: 16px;
  color: #6c757d;
}`],
})
export class ComponentAdminDataComponentComponent implements OnInit {
    public id = "";
    public title = "";
    public list = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }
    public async load() {
        let { code, data } = await wiz.call("load")
        if (code === 200) {
            this.list = data;
            await this.service.render();
        }
    }
    public find(id) {
        const list = this.list.filter(item => {
            return item.id !== id;
        });
        return list;
    }

    public async update(item) {
        if (!item) item = { id: this.id, title: this.title }
        let list = this.list.filter(component => { return component.id !== item.id; });
        list.push({ id: item.id, title: item.title, count: 0 });
        let value = JSON.stringify({ data: list });
        await wiz.call("update", { value });
        this.id = "";
        this.title = "";
        await this.load();
    }

    public async alert(title: string, message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async delete(item) {
        let res = await this.alert("카테고리 삭제", "카테고리를 정말 삭제하시겠습니까?", "error", "취소", "삭제");
        if (res) {
            let list = this.list.filter(component => { return component.id !== item.id; });
            let value = JSON.stringify({ data: list });
            await wiz.call("update", { value });
            await this.load();
        }
    }
}

export default ComponentAdminDataComponentComponent;