import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.admin');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-admin',
template: templateSource || '',
    styles: [``],
})
export class ComponentNavAdminComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(['manager', 'admin'], '/');
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public hasAuth(roles: string) {
        roles = roles.split(",");
        return roles.includes(this.service.auth.session.role);
    }
    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "group flex gap-x-3 rounded-md bg-gray-50 p-2 text-sm font-normal leading-6 text-indigo-600";
        }
        return "group flex gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }

}

export default ComponentNavAdminComponent;