import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.manager');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-manager',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.admin.manager/view.scss */
.bg-admin-facility {
  padding: 3rem;
}

.workspace-list-item {
  white-space: nowrap;
  font-size: 1.125rem !important;
  font-weight: bold;
}

.pb-4 {
  max-width: none;
}

.page-title {
  font-size: 24px;
}

.mt-4-custom {
  margin-top: 1.5rem;
}

.mb-4-custom {
  margin-bottom: 1.5rem;
}

.p-6-custom {
  padding: 1.5rem;
}

.text-center-custom {
  text-align: center;
}

.btn-season-custom {
  background-color: #3E3EF4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-season-custom i {
  margin-right: 5px;
}

.pagination-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-prev-custom,
.pagination-next-custom {
  background: none;
  border: none;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
}

.pagination-list-custom {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination-item-custom {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination-item-custom.active {
  font-weight: bold;
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.ltable-cu {
  background: #F9F9F9 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
  text-align: justify;
}
.ltable-cu th {
  font-size: 1rem;
  background-color: var(--tblr-table-bg);
}

.cursor-pointer td {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1.1rem;
  font-weight: bold;
}

.tnum {
  text-align: center !important;
  width: 10rem;
}

.tday {
  width: 16rem;
}

table tbody {
  font-size: 1.1rem;
  font-weight: bold;
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 14px;
  height: 44px;
  padding: 5px 15px;
  width: 100%;
  max-width: 254px;
}

.search-icon {
  margin-right: 10px;
  color: #6c757d;
}

.search-input {
  border: none;
  background-color: transparent;
  outline: none;
  flex: 1;
  font-size: 16px;
  color: #6c757d;
}`],
})
export class ComponentAdminManagerComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/");
        await this.load();
    }

    public listloaded: boolean = false;
    public list: any = [];
    public status: string = 'active';

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load(page: number = 1, status: any = null) {
        if (!status) status = this.status;
        this.listloaded = false;
        await this.service.render();
        let { code, data } = await wiz.call("list", { page: page, text: this.search.text, status: status });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        // this.status = status;
        await this.service.render();
    }


    public async changeRole(item: any) {
        if (item.role === 'admin') {
            await this.alert('관리자 권한은 변경할 수 없습니다.', 'error');
            return;
        }
        
        let res = false;
        if (item.role === 'manager') {
            res = await this.alert('관리자 권한을 해제하시겠습니까?', 'error');
        } else {
            res = await this.alert('관리자로 지정하시겠습니까?', 'success');
        }
        if (!res) return;

        item.role = item.role === 'manager' ? 'user' : 'manager';
        await wiz.call("changeRole", { id: item.id, role: item.role });
        await this.load(this.search.page);
    }

}

export default ComponentAdminManagerComponent;