import '@angular/compiler';
import { PortalWorksProjectDriveComponent } from './portal.works.project.drive/portal.works.project.drive.component';
import { PortalDizestEditorHealthComponent } from './portal.dizest.editor.health/portal.dizest.editor.health.component';
import { LayoutFacilityComponent } from './layout.facility/layout.facility.component';
import { ComponentAdminFacilityListComponent } from './component.admin.facility.list/component.admin.facility.list.component';
import { PortalDizestEditorWorkflowSidebarPipComponent } from './portal.dizest.editor.workflow.sidebar.pip/portal.dizest.editor.workflow.sidebar.pip.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalDizestEditorWorkflowSidebarCodeComponent } from './portal.dizest.editor.workflow.sidebar.code/portal.dizest.editor.workflow.sidebar.code.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalDizestEditorWorkflowSidebarApiComponent } from './portal.dizest.editor.workflow.sidebar.api/portal.dizest.editor.workflow.sidebar.api.component';
import { PortalDizestEditorWorkflowSidebarAppsComponent } from './portal.dizest.editor.workflow.sidebar.apps/portal.dizest.editor.workflow.sidebar.apps.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { ComponentAdminNoticeEditComponent } from './component.admin.notice.edit/component.admin.notice.edit.component';
import { ComponentModalContractComponent } from './component.modal.contract/component.modal.contract.component';
import { PortalKreonetPlaceholderUserComponent } from './portal.kreonet.placeholder.user/portal.kreonet.placeholder.user.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalWorksProjectMemberComponent } from './portal.works.project.member/portal.works.project.member.component';
import { PortalDizestEditorWorkflowNodeComponent } from './portal.dizest.editor.workflow.node/portal.dizest.editor.workflow.node.component';
import { ComponentModalDrawingComponent } from './component.modal.drawing/component.modal.drawing.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentAdminProjectEditComponent } from './component.admin.project.edit/component.admin.project.edit.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { ComponentAdminNoticeListComponent } from './component.admin.notice.list/component.admin.notice.list.component';
import { ComponentProjectDrawingComponent } from './component.project.drawing/component.project.drawing.component';
import { PortalKreonetPopupDevicesComponent } from './portal.kreonet.popup.devices/portal.kreonet.popup.devices.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalWorksProjectMeetingComponent } from './portal.works.project.meeting/portal.works.project.meeting.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalWorksProjectPlanComponent } from './portal.works.project.plan/portal.works.project.plan.component';
import { PageContractItemComponent } from './page.contract.item/page.contract.item.component';
import { ComponentProjectListComponent } from './component.project.list/component.project.list.component';
import { ComponentProjectInfoComponent } from './component.project.info/component.project.info.component';
import { PortalDizestEditorWorkflowSidebarInfoComponent } from './portal.dizest.editor.workflow.sidebar.info/portal.dizest.editor.workflow.sidebar.info.component';
import { ComponentAdminContractListComponent } from './component.admin.contract.list/component.admin.contract.list.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { ComponentAdminTemplateListComponent } from './component.admin.template.list/component.admin.template.list.component';
import { ComponentMainPlusComponent } from './component.main.plus/component.main.plus.component';
import { PageFacilityItemComponent } from './page.facility.item/page.facility.item.component';
import { ComponentMainNoticeDetailComponent } from './component.main.notice.detail/component.main.notice.detail.component';
import { ComponentAuthSignupComponent } from './component.auth.signup/component.auth.signup.component';
import { ComponentContractListComponent } from './component.contract.list/component.contract.list.component';
import { ComponentAdminTemplateFrameComponent } from './component.admin.template.frame/component.admin.template.frame.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { ComponentAdminUserComponent } from './component.admin.user/component.admin.user.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { PortalKreonetGraphPieComponent } from './portal.kreonet.graph.pie/portal.kreonet.graph.pie.component';
import { PortalKreonetChartTrafficComponent } from './portal.kreonet.chart.traffic/portal.kreonet.chart.traffic.component';
import { ComponentModalFacilityEditComponent } from './component.modal.facility.edit/component.modal.facility.edit.component';
import { ModalComponent } from './modal/modal.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PageAuthLoginComponent } from './page.auth.login/page.auth.login.component';
import { PageAuthForgotpwComponent } from './page.auth.forgotpw/page.auth.forgotpw.component';
import { ComponentProjectLogComponent } from './component.project.log/component.project.log.component';
import { ComponentAuthLoginComponent } from './component.auth.login/component.auth.login.component';
import { ComponentAdminProjectListComponent } from './component.admin.project.list/component.admin.project.list.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { ComponentAdminTemplateEditComponent } from './component.admin.template.edit/component.admin.template.edit.component';
import { ComponentAdminTemplateFormComponent } from './component.admin.template.form/component.admin.template.form.component';
import { PageNoticeItemComponent } from './page.notice.item/page.notice.item.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalKreonetWidgetNavComponent } from './portal.kreonet.widget.nav/portal.kreonet.widget.nav.component';
import { ComponentModalFacilityComponent } from './component.modal.facility/component.modal.facility.component';
import { ComponentProjectInspectionComponent } from './component.project.inspection/component.project.inspection.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PortalKreonetWidgetWeathermapComponent } from './portal.kreonet.widget.weathermap/portal.kreonet.widget.weathermap.component';
import { ComponentFacilityListComponent } from './component.facility.list/component.facility.list.component';
import { PortalKreonetWidgetAvailabilityComponent } from './portal.kreonet.widget.availability/portal.kreonet.widget.availability.component';
import { ComponentAdminManagerComponent } from './component.admin.manager/component.admin.manager.component';
import { PortalDizestEditorDefaultComponent } from './portal.dizest.editor.default/portal.dizest.editor.default.component';
import { PortalWorksWidgetProjectIssueboardIssueComponent } from './portal.works.widget.project.issueboard.issue/portal.works.widget.project.issueboard.issue.component';
import { PortalWorksMessageBodyComponent } from './portal.works.message.body/portal.works.message.body.component';
import { ComponentAdminDataFacilityComponent } from './component.admin.data.facility/component.admin.data.facility.component';
import { PageProjectItemComponent } from './page.project.item/page.project.item.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { ComponentMainNoticeComponent } from './component.main.notice/component.main.notice.component';
import { ComponentContractInfoComponent } from './component.contract.info/component.contract.info.component';
import { PageAuthForgotemailComponent } from './page.auth.forgotemail/page.auth.forgotemail.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { ComponentMainProjectComponent } from './component.main.project/component.main.project.component';
import { ComponentModalTemplateComponent } from './component.modal.template/component.modal.template.component';
import { ComponentInspectionCheckComponent } from './component.inspection.check/component.inspection.check.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { ComponentAuthForgotpwComponent } from './component.auth.forgotpw/component.auth.forgotpw.component';
import { PortalKreonetPlaceholderCardComponent } from './portal.kreonet.placeholder.card/portal.kreonet.placeholder.card.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PageAuthSignupComponent } from './page.auth.signup/page.auth.signup.component';
import { PortalDizestEditorWorkflowSidebarAiComponent } from './portal.dizest.editor.workflow.sidebar.ai/portal.dizest.editor.workflow.sidebar.ai.component';
import { ComponentAdminDataComponentComponent } from './component.admin.data.component/component.admin.data.component.component';
import { ComponentAdminDataDamageComponent } from './component.admin.data.damage/component.admin.data.damage.component';
import { ComponentModalDrawingEditComponent } from './component.modal.drawing.edit/component.modal.drawing.edit.component';
import { PageAuthenComponent } from './page.authen/page.authen.component';
import { PortalWorksProjectInfoComponent } from './portal.works.project.info/portal.works.project.info.component';
import { ComponentFacilityInfoComponent } from './component.facility.info/component.facility.info.component';
import { ComponentAdminTemplateItemComponent } from './component.admin.template.item/component.admin.template.item.component';
import { PortalWorksProjectIssueboardComponent } from './portal.works.project.issueboard/portal.works.project.issueboard.component';
import { PortalWorksProjectIssueboardIssueComponent } from './portal.works.project.issueboard.issue/portal.works.project.issueboard.issue.component';
import { ComponentAdminContractEditComponent } from './component.admin.contract.edit/component.admin.contract.edit.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";

@NgModule({
    declarations: [
        AppComponent,
        PortalWorksProjectDriveComponent,
        PortalDizestEditorHealthComponent,
        LayoutFacilityComponent,
        ComponentAdminFacilityListComponent,
        PortalDizestEditorWorkflowSidebarPipComponent,
        PageAdminComponent,
        PortalDizestEditorWorkflowSidebarCodeComponent,
        PageMainComponent,
        PortalDizestEditorWorkflowSidebarApiComponent,
        PortalDizestEditorWorkflowSidebarAppsComponent,
        PortalSeasonViewerTreeComponent,
        PortalDizestEditorWorkflowComponent,
        ComponentAdminNoticeEditComponent,
        ComponentModalContractComponent,
        PortalKreonetPlaceholderUserComponent,
        PortalSeasonPagenationComponent,
        PortalWorksProjectMemberComponent,
        PortalDizestEditorWorkflowNodeComponent,
        ComponentModalDrawingComponent,
        PortalSeasonUiDropdownComponent,
        ComponentAdminProjectEditComponent,
        PortalDizestEditorImageComponent,
        ComponentAdminNoticeListComponent,
        ComponentProjectDrawingComponent,
        PortalKreonetPopupDevicesComponent,
        PortalSeasonAlertComponent,
        PortalWorksProjectMeetingComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalWorksProjectPlanComponent,
        PageContractItemComponent,
        ComponentProjectListComponent,
        ComponentProjectInfoComponent,
        PortalDizestEditorWorkflowSidebarInfoComponent,
        ComponentAdminContractListComponent,
        PortalDizestEditorVideoComponent,
        ComponentAdminTemplateListComponent,
        ComponentMainPlusComponent,
        PageFacilityItemComponent,
        ComponentMainNoticeDetailComponent,
        ComponentAuthSignupComponent,
        ComponentContractListComponent,
        ComponentAdminTemplateFrameComponent,
        PageMypageComponent,
        ComponentAdminUserComponent,
        PortalSeasonLoadingHexaComponent,
        LayoutUserComponent,
        PortalKreonetGraphPieComponent,
        PortalKreonetChartTrafficComponent,
        ComponentModalFacilityEditComponent,
        ModalComponent,
        PortalDizestEditorSettingComponent,
        PageAuthLoginComponent,
        PageAuthForgotpwComponent,
        ComponentProjectLogComponent,
        ComponentAuthLoginComponent,
        ComponentAdminProjectListComponent,
        PortalSeasonTabComponent,
        ComponentAdminTemplateEditComponent,
        ComponentAdminTemplateFormComponent,
        PageNoticeItemComponent,
        LayoutEmptyComponent,
        PortalDizestDriveComponent,
        PortalKreonetWidgetNavComponent,
        ComponentModalFacilityComponent,
        ComponentProjectInspectionComponent,
        PortalSeasonLoadingComponent,
        ComponentNavUserComponent,
        PortalKreonetWidgetWeathermapComponent,
        ComponentFacilityListComponent,
        PortalKreonetWidgetAvailabilityComponent,
        ComponentAdminManagerComponent,
        PortalDizestEditorDefaultComponent,
        PortalWorksWidgetProjectIssueboardIssueComponent,
        PortalWorksMessageBodyComponent,
        ComponentAdminDataFacilityComponent,
        PageProjectItemComponent,
        ComponentNavAdminComponent,
        ComponentMainNoticeComponent,
        ComponentContractInfoComponent,
        PageAuthForgotemailComponent,
        LayoutAdminComponent,
        ComponentMainProjectComponent,
        ComponentModalTemplateComponent,
        ComponentInspectionCheckComponent,
        PortalSeasonStatusbarComponent,
        ComponentAuthForgotpwComponent,
        PortalKreonetPlaceholderCardComponent,
        PortalDizestEditorTerminalComponent,
        PageAuthSignupComponent,
        PortalDizestEditorWorkflowSidebarAiComponent,
        ComponentAdminDataComponentComponent,
        ComponentAdminDataDamageComponent,
        ComponentModalDrawingEditComponent,
        PageAuthenComponent,
        PortalWorksProjectInfoComponent,
        ComponentFacilityInfoComponent,
        ComponentAdminTemplateItemComponent,
        PortalWorksProjectIssueboardComponent,
        PortalWorksProjectIssueboardIssueComponent,
        ComponentAdminContractEditComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        HttpClientModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }