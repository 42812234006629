import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.inspection.check');
import { OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';

@Component({
    selector: 'wiz-component-inspection-check',
template: templateSource || '',
    styles: [``],
})
export class ComponentInspectionCheckComponent implements OnInit {
    @Input() id: string;
    @Input() title: any;
    @Input() frame: any;
    @Input() items: any;
    @Input() conditions: any;
    public modal: any = { show: false, result: null };
    public uploadedFiles: { [key: string]: boolean } = {};
    
    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    public config: any = {
        col: {
            animation: 0
        },
        child: {
            animation: 0,
            group: 'list'
        }
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.checkUploadedFiles();

        try {
            const { code, data } = await wiz.call("load", { data: JSON.stringify({ id: this.id }) });
            if (code === 200 && data) {
                const inspectorIds = data.inspector ? data.inspector.split(',') : [];
                const userId = this.service.auth.session.id;

                if (!inspectorIds.includes(userId)) {
                    await this.alert("체크리스트 작성 권한이 없습니다.", "권한 없음");
                    this.location.back();
                    return;
                }

                this.title = data.name;
                this.frame = data.template;
                this.items = data.response;
                this.conditions = data.conditions || [];
                await this.service.render();
            } else {
                await this.alert("프로젝트 정보를 불러오는 데 실패했습니다.", "오류");
                this.service.href('/');
            }
        } catch (error) {
            await this.alert("프로젝트 정보를 불러오는 데 실패했습니다.", "오류");
            this.service.href('/');
        }
    }

    public hasRow() {
        return this.frame.filter(item => item.child.length > 0);
    }

    public row() {
        let answer = [...this.frame];
        this.frame.forEach((item, i) => {
            if (item.type == "col") {
                answer.splice(i, 1, ...item.child);
            }
        });
        return answer;
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async extract() {
        let body = { title: this.title, frame: this.frame, items: this.items, conditions: this.conditions };
        let download = wiz.url("extract?data=" + JSON.stringify({ id: this.id }));
        window.open(download, '_blank');
    }
    public async extract_all() {
        await this.service.loading.show();
        let images = wiz.url("images?id=" + this.id);
        window.open(images, '_blank');
        await this.service.loading.hide();
    }

    public async upload(index) {
        let fd = new FormData();
        let files = await this.service.file.select();
        if (files.length == 0) return;

        Array.from(files).forEach((file, i) => {
            fd.append('file[]', files[i]);
        });
        fd.append("id", this.id + "/" + index);
        const url = wiz.url("upload");
        const { code, data } = await this.service.file.upload(url, fd);

        if (code === 200) {
            this.uploadedFiles[index] = true;  // 업로드 완료 표시
            await this.service.render();
        }
    }

    public async checkUploadedFiles() {
        for (let i = 0; i < this.items.length; i++) {
            let id = this.id + "/" + (i + 1);
            let { code, data } = await wiz.call("files", { id });

            if (code === 200 && data.length > 0) {
                this.uploadedFiles[i + 1] = true;  // 기존 파일 존재
            } else {
                this.uploadedFiles[i + 1] = false;
            }
        }
        await this.service.render();
    }


    public async save() {
        let body = { id: this.id, response: this.items };
        const { code, data } = await wiz.call("save", { data: JSON.stringify(body) });
        if (code === 200) alert("저장완료!");
    }

    public goBack() {
        this.location.back();
    }

    public async removeFile(index: number) {
        let confirmDelete = await this.alert("이 파일을 삭제하시겠습니까?", "파일 삭제", "error", "삭제", "취소");
        if (!confirmDelete) return;

        let id = this.modal.id;
        let fileName = this.modal.result[index].split('/').pop();

        let { code } = await wiz.call("delete_file", { id, fileName });

        if (code === 200) {
            this.modal.result.splice(index, 1);

            if (this.modal.result.length === 0) {
                let index = parseInt(id.split('/').pop());
                this.uploadedFiles[index] = false;
            }
            await this.service.render();
        } else {
            await this.alert("파일 삭제에 실패했습니다.", "오류", "error");
        }
    }

    public async closeModal() {
        this.modal.show = false;
        await this.checkUploadedFiles();
        await this.service.render();
    }

    public async modalShow(index) {
        let id = this.id + "/" + index;
        let { code, data } = await wiz.call("files", { id });

        if (code === 200 && data.length > 0) {
            this.modal = { show: true, result: data.map(el => wiz.url("image?id=" + `${id}/${el}`)), id: id };
            this.uploadedFiles[index] = true;
        } else {
            this.uploadedFiles[index] = false;
        }

        await this.service.render();
    }

}

export default ComponentInspectionCheckComponent;