import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.facility.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Location } from '@angular/common';

@Component({
    selector: 'wiz-component-facility-info',
template: templateSource || '',
    styles: [``],
})
export class ComponentFacilityInfoComponent implements OnInit {
    @Input() id: string = '';
    public data: any = {};
    public list: any = [];

    constructor(@Inject( Service) public service: Service,@Inject( Location)  private location: Location) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
        await this.service.render();
    }

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        if (!this.id) this.id = WizRoute.segment.id;
        const { code, data } = await wiz.call("load", { id: this.id });
        if (code === 200) {
            this.data = data.data;
        } else {
            await this.alert("데이터를 불러오는 데 실패했습니다.");
        }
        await this.service.render();
    }

    public async goBack() {
        this.location.back();
    }
}

export default ComponentFacilityInfoComponent;