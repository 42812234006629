import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.project.edit');
import { OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-project-edit',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminProjectEditComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( ChangeDetectorRef)  private cdr: ChangeDetectorRef) { }

    @Input() id: string;
    public items: any = [];
    public loaded: boolean = false;
    public search: any = { page: 1 };
    public status: any = false;
    public fd = new FormData();
    public drawing = [{ name: "", value: "" }];
    public data: any = {
        name: '',
        facility_id: '',
        facility_name: '',
        cost: '',
        method: '',
        template: '',
        type: '',
        status: '',
        inspector: [],  // 작업자 객체 배열
        start: '',
        end: '',
        created: '',
        updated: '',
    };

    public facilities: any = [];
    public checklistTemplates: any[] = [];
    public users: any = [];
    public inspectorSearch: string = '';
    public inspectorResults: any = [];
    public showInspectorModal: boolean = false;
    public selectedInspectors: any = null;

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async loadFacilities() {
        const { code, data } = await wiz.call("load_facilities");
        if (code === 200) {
            this.facilities = data;
        } else {
            await this.alert("시설물 데이터를 불러오는데 실패했습니다.");
        }
    }

    public async loadChecklistTemplates() {
        let { code, data } = await wiz.call("load_checklist_templates");
        if (code === 200) {
            this.checklistTemplates = data;
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(["admin", "manager"], "/");
        if (this.id !== "new") await this.load();
        await this.loadFacilities();
        await this.loadChecklistTemplates();
        await this.service.render();
    }

    public async load() {
        try {
            this.id = parseInt(WizRoute.segment.id, 10);
            const { code, data } = await wiz.call("load", { id: this.id });
            if (code === 200) {
                this.data = data;
                this.drawing = data.drawing ?
                    data.drawing.split(",").map(el => ({ value: "", name: el })) :
                    [{ name: "", value: "" }];
                console.log(data.drawing, data.drawing.split(",").map(el => (data.drawing.split(',').includes(el.name))));
                console.log(this.drawing)
                // Load contract and facility names
                const contract = await wiz.call("get_contract_name", { id: this.data.contract });
                if (contract.code === 200) {
                    this.data.contract_name = contract.name;
                }

                const facility = await wiz.call("get_facility_name", { id: this.data.facility_id });
                if (facility.code === 200) {
                    this.data.facility_name = facility.name;
                }
                await this.service.render();
            } else {
                await this.alert("데이터를 불러오는 데 실패했습니다.");
            }
        } catch (error) {
            await this.alert("데이터를 불러오는 데 실패했습니다. ERROR:" + error);
        }
    }

    public async loadAllUsers(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        await this.service.render();

        const { code, data } = await wiz.call('search_users', this.search);
        if (code === 200) {
            let { rows, lastpage, total } = data;

            this.inspectorResults = rows
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;

            this.totalItems = total;  // 전체 게시물 수 저장
            this.pagenation.start = startpage;
            this.pagenation.end = lastpage;

            this.loaded = true;
            this.cdr.detectChanges();
        }

        await this.service.render();
    }

    public async searchInspectors() {
        const { code, data } = await wiz.call('search_users', { query: this.inspectorSearch });
        if (code === 200) {
            this.inspectorResults = data;
            this.cdr.detectChanges();
        }
    }

    public openInspectorModal() {
        this.showInspectorModal = true;
        this.loadAllUsers(); // Load all users initially
    }

    public closeInspectorModal() {
        this.showInspectorModal = false;
    }

    public addInspectors() {
        const selectedInspectors = this.inspectorResults.filter(user => user.selected).map(user => ({ id: user.id, name: user.name })); // 객체로 저장
        this.data.inspector = [...this.data.inspector, ...selectedInspectors]; // 중복 방지
        this.closeInspectorModal();
        this.cdr.detectChanges(); // Change detection trigger
    }

    public removeInspector(inspector: any) {
        this.data.inspector = this.data.inspector.filter(i => i.id !== inspector.id);
        this.cdr.detectChanges(); // Change detection trigger
    }

    public async cancel() {
        await this.service.href('/admin/task/project');
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async submit() {
        // 유효성 검사: 용역과 시설물 필수
        if (!this.data.contract_name) {
            await this.alert('용역을 선택해 주세요.', 'error');
            return;
        }

        if (!this.data.facility_name) {
            await this.alert('시설물을 선택해 주세요.', 'error');
            return;
        }

        // `drawing`이 빈 배열일 경우 데이터베이스에 저장하지 않음
        if (this.drawing.length === 1 && !this.drawing[0].name && !this.drawing[0].value) {
            this.data.drawing = "";
        } else {
            this.data.drawing = this.drawing.map(el => el.name).join(",");
        }

        let body = JSON.parse(JSON.stringify(this.data));
        body.inspector = this.data.inspector.map(inspector => inspector.id).join(',');
        this.fd.append("body", JSON.stringify(body));

        const call = this.id === "new" ? "save" : "update";
        let url = wiz.url(call);
        let { code, data } = await this.service.file.upload(url, this.fd);
        if (code !== 200) {
            return await this.alert(data);
        }
        await this.alert((this.id === "new" ? "생성" : "수정") + "이 완료되었습니다!", "success");
        await this.service.href("/admin/task/project");
    }


    public async init() {
        this.data = {
            name: '',
            facility_id: '',
            cost: '',
            method: '',
            contract: '',
            template: '',
            type: '',
            pm: '',
            status: '',
            inspector: [],  // 작업자 객체 배열
            start: '',
            end: '',
            created: '',
            updated: '',
        };
        await this.service.render();
    }

    public facilityModal: any;
    public facilityEvent: any = {
        close: async () => {
            this.facilityModal = null;
            await this.service.render();
        },
        select: async (item) => {
            this.data.facility_id = item.id;
            this.data.facility_name = item.name;
            this.data.drawing = item.drawing;
            this.drawing = item.drawing.split(",").map(el => ({ value: "", name: el }));

            if (this.data.contract_name && this.data.facility_name) {
                this.data.name = `${this.data.contract_name} ${this.data.facility_name}`;
            }

            this.facilityModal = null;
            await this.service.render();
        }
    }
    public async facility() {
        this.facilityModal = { text: this.data.facility || "" };
        await this.service.render();
    }

    public contractModal: any;
    public contractEvent: any = {
        close: async () => {
            this.contractModal = null;
            await this.service.render();
        },
        select: async (item) => {
            this.data.contract = item.id;
            this.data.contract_name = item.name;
            delete item.name;
            delete item.id;
            delete item.created;
            delete item.updated;
            item.start = item.start.split(" ")[0]
            item.end = item.end.split(" ")[0]

            if (this.data.contract_name && this.data.facility_name) {
                this.data.name = `${this.data.contract_name} ${this.data.facility_name}`;
            }

            this.data = { ...this.data, ...item };
            this.contractModal = null;
            await this.service.render();
        }
    }
    public async contract() {
        this.contractModal = { text: this.data.contract || "" };
        await this.service.render();
    }

    public templateModal: any;
    public templateEvent: any = {
        close: async () => {
            this.templateModal = null;
            await this.service.render();
        },
        select: async (item) => {
            this.data.template = item.id;
            this.data.template_title = item.title;
            this.templateModal = null;
            await this.service.render();
        }
    }
    public async template() {
        this.templateModal = { text: this.data.template || "" };
        await this.service.render();
    }

    public async upload(i) {
        let file = await this.service.file.select({ multiple: false });
        if (file.length == 0) return;
        this.drawing[i].value = file[0].name;
        this.fd.append('file[]', file[0], this.drawing[i].name);
        await this.service.render();
    }
    public async remove(i) {
        console.log(this.drawing);
        this.drawing.splice(i, 1);
        this.files.splice(i, 1);
        await this.service.render();
    }
}

export default ComponentAdminProjectEditComponent;