import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.user');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-user',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminUserComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/");
        await this.load();
    }

    public listloaded: boolean = false;
    public list: any = [];
    public status: string = 'active';

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load(page: number = 1, status: any = null) {
        if (!status) status = this.status;
        this.listloaded = false;
        // await this.service.render();
        let { code, data } = await wiz.call("list", { page: page, text: this.search.text, status: status });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        // this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.listloaded = true;
        // this.status = status;
        await this.service.render();
    }


    public async changeRole(item: any) {
        if (item.role === 'admin') {
            await this.alert('관리자 권한은 변경할 수 없습니다.', 'error');
            return;
        }

        let res = false;
        if (item.role === 'manager') {
            res = await this.alert('관리자 권한을 해제하시겠습니까?', 'error');
        } else {
            res = await this.alert('관리자로 지정하시겠습니까?', 'success');
        }
        if (!res) return;

        item.role = item.role === 'manager' ? 'user' : 'manager';
        await wiz.call("changeRole", { id: item.id, role: item.role });
        await this.load(this.search.page);
    }

}

export default ComponentAdminUserComponent;