import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.facility.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-facility-list',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminFacilityListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public status: any = false;

    public data: any = {
        address: '',
        name: '',
        facility_type: '',
        facility_category: '',
        facility_class: '',
        completion_date: '',
    };

    public itemsPerPage: number = 20;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(["admin", "manager"], "/");
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        if (page === 1) {
            this.items = rows; // 첫 페이지일 경우 데이터 초기화
        } else {
            this.items = [...this.items, ...rows]; // 다른 페이지일 경우 기존 데이터에 추가
        }
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public loadMore() {
        this.search.page++;
        this.load(this.search.page);
    }

    public getImageByFacilityType(facilityType: string): string {
        const imageMap: { [key: string]: string } = {
            '건축물': 'assets/icons/building.svg',
            '교량': 'assets/icons/bridge.svg',
            '터널': 'assets/icons/tunnel.svg',
            '항만': 'assets/icons/port.svg',
            '항만외곽시설': 'assets/icons/harbor.svg',
            '댐': 'assets/icons/dam.svg',
            '하구둑': 'assets/icons/estuary.svg',
            '수문': 'assets/icons/gate.svg',
            '제방': 'assets/icons/embankment.svg',
            '배수펌프장': 'assets/icons/pump.svg',
            '상수도': 'assets/icons/water.svg',
            '하수처리장': 'assets/icons/sewage.svg',
            '옹벽': 'assets/icons/retaining_wall.svg',
            '절토사면': 'assets/icons/slope.svg',
            '공동구': 'assets/icons/utility.svg',
        };
        return imageMap[facilityType] || 'assets/icons/default.png';
    }

    public async submit() {
        let body = JSON.parse(JSON.stringify(this.data));
        let { code, data } = await wiz.call("submit", body);
        if (code !== 200) {
            return await this.alert(data);
        }
        await this.alert("생성이 완료되었습니다!", "success");
        await this.service.href("/admin/facility");
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async init() {
        this.data = {
            address: '',
            name: '',
            facility_type: '',
            facility_category: '',
            facility_class: '',
            completion_date: '',
        };
        await this.service.render();
    }

    public editModal: any;
    public editEvent: any = {
        close: async () => {
            this.editModal = null;
            await this.load(); // 수정 후 목록을 다시 로드
            await this.service.render();
        },
    }

    public async edit(id) {
        this.editModal = id;
        await this.service.render();
    }
}

export default ComponentAdminFacilityListComponent;