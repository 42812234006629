import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.auth.login');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-auth-login',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.auth.login/view.scss */
@charset "UTF-8";
/* 기본 스타일: 웹 (1920x1080 기준) */
.login-brand-image {
  /* Layout Properties */
  top: 294px;
  left: 820px;
  width: 260px;
  height: 65px;
  /* UI Properties */
  opacity: 1;
}

.container-tight {
  top: 171px;
  left: 628px;
  width: 664px;
  height: 716px;
}

.mb-4 {
  margin-top: 8.5rem;
  margin-bottom: 0 !important;
}

.login-text {
  margin-top: 4.5rem;
  font: normal normal bold 30px/38px SUIT;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.login-content {
  margin-top: 1.5rem;
  font: normal normal normal 16px/20px SUIT;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
}

.card-login-field {
  width: 320px;
  height: 55px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #DCDCDC;
  border-radius: 12px;
  opacity: 1;
  margin-left: 7rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

input::placeholder {
  font: normal normal normal 18px/23px SUIT;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
}

.login-btn {
  width: 320px;
  height: 55px;
  background: #3E3EF4 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  margin-left: 7rem;
  font: normal normal bold 18px/23px SUIT;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.login-plus {
  justify-content: center;
  display: flex;
}

.login-find {
  text-decoration-skip-ink: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 8.3px;
  margin-right: 10px;
  font: normal normal normal 14px/18px SUIT;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
}

/* 모바일 스타일: 모바일 기기에서도 잘 작용되게 */
@media (max-width: 768px) {
  .page-center {
    height: 100% !important;
    padding-bottom: 600px !important;
  }
  .login-brand-image {
    top: 20px;
    left: 50%;
    width: 180px;
    height: 45px;
  }
  .card-md {
    top: 50px;
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
  .container-tight {
    top: 50px;
    left: 50%;
    width: 90%;
    height: auto;
  }
  .mb-4 {
    margin-top: 2rem;
    margin-bottom: 0 !important;
  }
  .login-text {
    margin-top: 2rem;
    font-size: 24px;
    line-height: 30px;
  }
  .login-content {
    margin-top: 1rem;
    font-size: 14px;
    line-height: 18px;
  }
  .card-login-field {
    width: 80%;
    height: 45px;
    margin-left: 10%;
    margin-bottom: 0.5rem;
  }
  .login-btn {
    width: 80%;
    height: 45px;
    margin-left: 10%;
    font-size: 16px;
    line-height: 20px;
  }
  .login-plus {
    flex-direction: row;
    align-items: center;
  }
  .login-find {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 3px;
    font-size: 12px;
    line-height: 16px;
  }
}`],
})
export class ComponentAuthLoginComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/main");
        // this.alert("asdfasdf")
    }

    public data: any = {
        email: '',
        password: '',
    };

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status,
        });
    }

    public async login() {
        let user = JSON.parse(JSON.stringify(this.data));
        user.password = this.service.auth.hash(user.password);

        let { code, data } = await wiz.call("login", user);
        if (code == 200) {
            location.href = '/';
            return;
        }
        await this.alert(data);
    }
}

export default ComponentAuthLoginComponent;