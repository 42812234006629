import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.contract.list');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-contract-list',
template: templateSource || '',
    styles: [``],
})
export class ComponentContractListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1, text: '', status: '', sort: 'created' };  // 정렬 옵션 추가
    public status: string = '';

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public totalItems: number = 0;  // 전체 게시물 수

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage, total } = data;
        this.items = rows;
        this.totalItems = total;
        this.pagenation.start = Math.floor((page - 1) / 10) * 10 + 1;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async filterByStatus(status: string, page: number = 1) {
        this.status = status;
        this.loaded = false;
        const { code, data } = await wiz.call("filter_by_status", { status, page, sort: this.search.sort });
        if (code != 200) return;
        let { rows, lastpage, total } = data;
        this.items = rows;
        this.totalItems = total;
        this.pagenation.start = Math.floor((page - 1) / 10) * 10 + 1;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public setTab(status: string) {
        this.search.status = status;
        this.filterByStatus(status, 1);
        this.load(1);
    }

    public getItemNumber(index: number): number {
        // 전체 게시물 수에서 현재 페이지와 인덱스를 고려하여 게시물 번호 계산
        return this.totalItems - ((this.search.page - 1) * 20 + index);
    }

    public onEnter(event: KeyboardEvent) {
        event.preventDefault();
        this.load();
    }
}

export default ComponentContractListComponent;