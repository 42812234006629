import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.data.damage');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-data-damage',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminDataDamageComponent implements OnInit {
    public id = "";
    public title = "";
    public list = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }
    public async load() {
        let { code, data } = await wiz.call("load")
        if (code === 200) {
            this.list = data;
            await this.service.render();
        }
    }
    public find(id) {
        const list = this.list.filter(item => {
            return item.id !== id;
        });
        return list;
    }

    public async update(item) {
        if (!item) item = { id: this.id, title: this.title }
        let list = this.list.filter(damage => { return damage.id !== item.id; });
        list.push({ id: item.id, title: item.title, count: 0 });
        let value = JSON.stringify({ data: list });
        await wiz.call("update", { value });
        this.id = "";
        this.title = "";
        await this.load();
    }

    public async alert(title: string, message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async upload(item) {
        let file = await this.service.file.select({ multiple: false });
        if (file.length == 0) return;
        let fd = new FormData();
        fd.append('file[]', file[0], item);
        let url = wiz.url("upload");
        let { code, data } = await this.service.file.upload(url, fd);
        if (code !== 200) {
            return await this.alert(data);
        }
        await this.service.render();
    }

    public async delete(item) {
        let res = await this.alert("카테고리 삭제", "카테고리를 정말 삭제하시겠습니까?", "error", "취소", "삭제");
        if (res) {
            let list = this.list.filter(damage => { return damage.id !== item.id; });
            let value = JSON.stringify({ data: list });
            await wiz.call("update", { value });
            await this.load();
        }
    }
}

export default ComponentAdminDataDamageComponent;