import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.auth.signup');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-auth-signup',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.auth.signup/view.scss */
@charset "UTF-8";
/* 기본 스타일 */
.login-brand-image {
  /* Layout Properties */
  top: 294px;
  left: 820px;
  width: 260px;
  height: 65px;
  /* UI Properties */
  opacity: 1;
  margin-top: 30px;
}

.card-body {
  align-self: center;
}

.login-content {
  margin-top: 1.5rem;
  font: normal normal normal 16px/20px SUIT;
  letter-spacing: 0px;
  color: #989898;
  opacity: 1;
  text-align: center;
  margin-bottom: 30px;
}

.error-message {
  color: #3E3EF4;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.page.page-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #536DFE;
  padding: 1rem;
}

.container-tight {
  max-width: 664px;
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.card.card-md {
  border: none;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.form-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
}

.form-control {
  width: 320px;
  height: 55px;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 12px;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.input-group.input-group-flat {
  position: relative;
}

.btn.btn-primary {
  background-color: #3E3EF4;
  border-color: #3E3EF4;
  color: white;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.btn.btn-primary:hover {
  background-color: #303F9F;
  border-color: #303F9F;
}

.mt-2 {
  margin-top: 0.5rem;
}

.text-muted {
  color: #6c757d;
}

.mr-2 {
  margin-right: 0.5rem;
}

.form-footer {
  margin-top: 1rem;
}

.input-icon-addon-custom svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* 반응형 디자인 */
@media (max-width: 768px) {
  .container-tight {
    padding: 1.5rem;
  }
  .card.card-md {
    padding: 1.5rem;
  }
  .card-title {
    font-size: 1.25rem;
  }
  .login-brand-image {
    top: 20px;
    left: 50%;
    width: 180px;
    height: 45px;
  }
  .login-content {
    margin-top: 1rem;
    font-size: 14px;
    line-height: 18px;
  }
  .form-control {
    font-size: 0.875rem;
  }
  .btn.btn-primary {
    font-size: 0.875rem;
    padding: 0.625rem 1rem;
  }
}
@media (max-width: 576px) {
  .container-tight {
    padding: 1rem;
  }
  .card.card-md {
    padding: 1rem;
  }
  .card-title {
    font-size: 1rem;
  }
  .form-control {
    font-size: 0.75rem;
  }
  .btn.btn-primary {
    font-size: 0.75rem;
    padding: 0.5rem 0.75rem;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mt-2 {
    margin-top: 0.5rem;
  }
}`],
})
export class PageAuthSignupComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(false, "/main");
    }

    public status: any = false;
    public data: any = {
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        phone: '',
        position: '',
        level: ''
    };

    public validation: any = {
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        phone: '',
        position: '',
        level: ''
    };

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async submit() {
        this.resetValidation();

        if (!this.validateEmail(this.data.email)) {
            this.validation.email = '올바른 이메일 형식이 아닙니다.';
        }

        if (this.data.password.length < 6) {
            this.validation.password = '비밀번호는 최소 6자 이상이어야 합니다.';
        }

        if (this.data.password !== this.data.confirmPassword) {
            this.validation.confirmPassword = '비밀번호가 일치하지 않습니다.';
        }

        if (!this.data.name) {
            this.validation.name = '이름을 입력해주세요.';
        }

        if (!this.validatePhone(this.data.phone)) {
            this.validation.phone = '올바른 전화번호 형식이 아닙니다. ';
        }

        if (!this.data.position) {
            this.validation.position = '직급을 입력해주세요.';
        }

        if (!this.data.level) {
            this.validation.level = '기술등급을 입력해주세요.';
        }

        if (Object.values(this.validation).some(error => error)) {
            return;
        }

        let user = JSON.parse(JSON.stringify(this.data));
        user.password = this.service.auth.hash(user.password);
        let { code, data } = await wiz.call("submit", user);
        if (code == 200) {
            await this.alert("회원가입이 완료되었습니다.", "success");
            await this.service.render();
            location.href = "/auth/login";
            return;
        }
        await this.alert(data);
    }

    private resetValidation() {
        this.validation = {
            email: '',
            password: '',
            confirmPassword: '',
            name: '',
            phone: '',
            position: '',
            level: ''
        };
    }

    private validateEmail(email: string): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    }

    private validatePhone(phone: string): boolean {
        const re = /^\d{3}-\d{3,4}-\d{4}$/;
        return re.test(String(phone));
    }

    public formatPhone() {
        const trimmed = this.data.phone.replace(/\D+/g, '');

        if (trimmed.length === 11) {
            this.data.phone = trimmed.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else if (trimmed.length === 10) {
            this.data.phone = trimmed.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
    }
}

export default PageAuthSignupComponent;