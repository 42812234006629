import '@angular/compiler';
import { PortalWorksProjectDriveComponent } from './portal.works.project.drive/portal.works.project.drive.component';
import { PortalDizestEditorHealthComponent } from './portal.dizest.editor.health/portal.dizest.editor.health.component';
import { LayoutFacilityComponent } from './layout.facility/layout.facility.component';
import { ComponentAdminFacilityListComponent } from './component.admin.facility.list/component.admin.facility.list.component';
import { PortalDizestEditorWorkflowSidebarPipComponent } from './portal.dizest.editor.workflow.sidebar.pip/portal.dizest.editor.workflow.sidebar.pip.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalDizestEditorWorkflowSidebarCodeComponent } from './portal.dizest.editor.workflow.sidebar.code/portal.dizest.editor.workflow.sidebar.code.component';
import { PageMainComponent } from './page.main/page.main.component';
import { PortalDizestEditorWorkflowSidebarApiComponent } from './portal.dizest.editor.workflow.sidebar.api/portal.dizest.editor.workflow.sidebar.api.component';
import { PortalDizestEditorWorkflowSidebarAppsComponent } from './portal.dizest.editor.workflow.sidebar.apps/portal.dizest.editor.workflow.sidebar.apps.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { ComponentAdminNoticeEditComponent } from './component.admin.notice.edit/component.admin.notice.edit.component';
import { ComponentModalContractComponent } from './component.modal.contract/component.modal.contract.component';
import { PortalKreonetPlaceholderUserComponent } from './portal.kreonet.placeholder.user/portal.kreonet.placeholder.user.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalWorksProjectMemberComponent } from './portal.works.project.member/portal.works.project.member.component';
import { PortalDizestEditorWorkflowNodeComponent } from './portal.dizest.editor.workflow.node/portal.dizest.editor.workflow.node.component';
import { ComponentModalDrawingComponent } from './component.modal.drawing/component.modal.drawing.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentAdminProjectEditComponent } from './component.admin.project.edit/component.admin.project.edit.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { ComponentAdminNoticeListComponent } from './component.admin.notice.list/component.admin.notice.list.component';
import { ComponentProjectDrawingComponent } from './component.project.drawing/component.project.drawing.component';
import { PortalKreonetPopupDevicesComponent } from './portal.kreonet.popup.devices/portal.kreonet.popup.devices.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalWorksProjectMeetingComponent } from './portal.works.project.meeting/portal.works.project.meeting.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalWorksProjectPlanComponent } from './portal.works.project.plan/portal.works.project.plan.component';
import { PageContractItemComponent } from './page.contract.item/page.contract.item.component';
import { ComponentProjectListComponent } from './component.project.list/component.project.list.component';
import { ComponentProjectInfoComponent } from './component.project.info/component.project.info.component';
import { PortalDizestEditorWorkflowSidebarInfoComponent } from './portal.dizest.editor.workflow.sidebar.info/portal.dizest.editor.workflow.sidebar.info.component';
import { ComponentAdminContractListComponent } from './component.admin.contract.list/component.admin.contract.list.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { ComponentAdminTemplateListComponent } from './component.admin.template.list/component.admin.template.list.component';
import { ComponentMainPlusComponent } from './component.main.plus/component.main.plus.component';
import { PageFacilityItemComponent } from './page.facility.item/page.facility.item.component';
import { ComponentMainNoticeDetailComponent } from './component.main.notice.detail/component.main.notice.detail.component';
import { ComponentAuthSignupComponent } from './component.auth.signup/component.auth.signup.component';
import { ComponentContractListComponent } from './component.contract.list/component.contract.list.component';
import { ComponentAdminTemplateFrameComponent } from './component.admin.template.frame/component.admin.template.frame.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { ComponentAdminUserComponent } from './component.admin.user/component.admin.user.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { PortalKreonetGraphPieComponent } from './portal.kreonet.graph.pie/portal.kreonet.graph.pie.component';
import { PortalKreonetChartTrafficComponent } from './portal.kreonet.chart.traffic/portal.kreonet.chart.traffic.component';
import { ComponentModalFacilityEditComponent } from './component.modal.facility.edit/component.modal.facility.edit.component';
import { ModalComponent } from './modal/modal.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { PageAuthLoginComponent } from './page.auth.login/page.auth.login.component';
import { PageAuthForgotpwComponent } from './page.auth.forgotpw/page.auth.forgotpw.component';
import { ComponentProjectLogComponent } from './component.project.log/component.project.log.component';
import { ComponentAuthLoginComponent } from './component.auth.login/component.auth.login.component';
import { ComponentAdminProjectListComponent } from './component.admin.project.list/component.admin.project.list.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { ComponentAdminTemplateEditComponent } from './component.admin.template.edit/component.admin.template.edit.component';
import { ComponentAdminTemplateFormComponent } from './component.admin.template.form/component.admin.template.form.component';
import { PageNoticeItemComponent } from './page.notice.item/page.notice.item.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalKreonetWidgetNavComponent } from './portal.kreonet.widget.nav/portal.kreonet.widget.nav.component';
import { ComponentModalFacilityComponent } from './component.modal.facility/component.modal.facility.component';
import { ComponentProjectInspectionComponent } from './component.project.inspection/component.project.inspection.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PortalKreonetWidgetWeathermapComponent } from './portal.kreonet.widget.weathermap/portal.kreonet.widget.weathermap.component';
import { ComponentFacilityListComponent } from './component.facility.list/component.facility.list.component';
import { PortalKreonetWidgetAvailabilityComponent } from './portal.kreonet.widget.availability/portal.kreonet.widget.availability.component';
import { ComponentAdminManagerComponent } from './component.admin.manager/component.admin.manager.component';
import { PortalDizestEditorDefaultComponent } from './portal.dizest.editor.default/portal.dizest.editor.default.component';
import { PortalWorksWidgetProjectIssueboardIssueComponent } from './portal.works.widget.project.issueboard.issue/portal.works.widget.project.issueboard.issue.component';
import { PortalWorksMessageBodyComponent } from './portal.works.message.body/portal.works.message.body.component';
import { ComponentAdminDataFacilityComponent } from './component.admin.data.facility/component.admin.data.facility.component';
import { PageProjectItemComponent } from './page.project.item/page.project.item.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { ComponentMainNoticeComponent } from './component.main.notice/component.main.notice.component';
import { ComponentContractInfoComponent } from './component.contract.info/component.contract.info.component';
import { PageAuthForgotemailComponent } from './page.auth.forgotemail/page.auth.forgotemail.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { ComponentMainProjectComponent } from './component.main.project/component.main.project.component';
import { ComponentModalTemplateComponent } from './component.modal.template/component.modal.template.component';
import { ComponentInspectionCheckComponent } from './component.inspection.check/component.inspection.check.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { ComponentAuthForgotpwComponent } from './component.auth.forgotpw/component.auth.forgotpw.component';
import { PortalKreonetPlaceholderCardComponent } from './portal.kreonet.placeholder.card/portal.kreonet.placeholder.card.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PageAuthSignupComponent } from './page.auth.signup/page.auth.signup.component';
import { PortalDizestEditorWorkflowSidebarAiComponent } from './portal.dizest.editor.workflow.sidebar.ai/portal.dizest.editor.workflow.sidebar.ai.component';
import { ComponentAdminDataComponentComponent } from './component.admin.data.component/component.admin.data.component.component';
import { ComponentAdminDataDamageComponent } from './component.admin.data.damage/component.admin.data.damage.component';
import { ComponentModalDrawingEditComponent } from './component.modal.drawing.edit/component.modal.drawing.edit.component';
import { PageAuthenComponent } from './page.authen/page.authen.component';
import { PortalWorksProjectInfoComponent } from './portal.works.project.info/portal.works.project.info.component';
import { ComponentFacilityInfoComponent } from './component.facility.info/component.facility.info.component';
import { ComponentAdminTemplateItemComponent } from './component.admin.template.item/component.admin.template.item.component';
import { PortalWorksProjectIssueboardComponent } from './portal.works.project.issueboard/portal.works.project.issueboard.component';
import { PortalWorksProjectIssueboardIssueComponent } from './portal.works.project.issueboard.issue/portal.works.project.issueboard.issue.component';
import { ComponentAdminContractEditComponent } from './component.admin.contract.edit/component.admin.contract.edit.component';

const INDEX_PAGE = "main";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutAdminComponent,
        "children": [
            {
                "path": "admin/:main?/:sub?/:id?",
                component: PageAdminComponent,
                "app_id": "page.admin"
            }
        ]
    },
    {
        component: LayoutUserComponent,
        "children": [
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "contract/:tab/:id?",
                component: PageContractItemComponent,
                "app_id": "page.contract.item"
            },
            {
                "path": "facility/:tab/:id?",
                component: PageFacilityItemComponent,
                "app_id": "page.facility.item"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "notice/:id",
                component: PageNoticeItemComponent,
                "app_id": "page.notice.item"
            },
            {
                "path": "project/:tab/:id?",
                component: PageProjectItemComponent,
                "app_id": "page.project.item"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "login",
                component: PageAuthLoginComponent,
                "app_id": "page.auth.login"
            },
            {
                "path": "forgotpw",
                component: PageAuthForgotpwComponent,
                "app_id": "page.auth.forgotpw"
            },
            {
                "path": "forgotemail",
                component: PageAuthForgotemailComponent,
                "app_id": "page.auth.forgotemail"
            },
            {
                "path": "signup",
                component: PageAuthSignupComponent,
                "app_id": "page.auth.signup"
            },
            {
                "path": "authen/:tab",
                component: PageAuthenComponent,
                "app_id": "page.authen"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }