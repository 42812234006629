import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.admin.notice.edit');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-admin-notice-edit',
template: templateSource || '',
    styles: [``],
})
export class ComponentAdminNoticeEditComponent implements OnInit {
    public data: any = {};

    @Input() id: string;

    constructor(@Inject( Service) public service: Service) { }

    public status: any = false;
    public data: any = {
        title: '',
        content: '',
        editor: '',
        created: '',
        updated: '',

    };;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        if (this.id !== "new") await this.load();
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load() {
        if (!this.id) this.id = WizRoute.segment.id;
        const { code, data } = await wiz.call("load", { id: this.id });
        if (code === 200) {
            this.data = data.data;
        } else {
            await this.alert("데이터를 불러오는 데 실패했습니다.");
        }
        await this.service.render();
    }

    public async cancel() {
        // 취소 버튼 클릭 시 실행할 코드
        await this.service.href('/admin/system/notice');
    }

    public async submit() {
        let body = JSON.parse(JSON.stringify(this.data));
        const call = this.id == "new" ? "save" : "update";
        let { code, data } = await wiz.call(call, body);
        if (code !== 200) {
            return await this.alert(data);
        }
        await this.alert((this.id == "new" ? "생성" : "수정") + "이 완료되었습니다!", "success");
        await this.service.href("/admin/system/notice");
    }

    public async init() {
        this.data = {
            title: '',
            content: '',
            editor: '',
            created: '',
            updated: '',
        };
        await this.service.render();
    }
}

export default ComponentAdminNoticeEditComponent;